import $ from "jquery";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React from "react";
import { Modal } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/@fancyapps/fancybox/dist/jquery.fancybox.css";
import "./CommBanner.scss";
import QmiReserveForm from "./QmiReserveForm.jsx";
import ScheduleTourForm from "./ScheduleTourForm.jsx";
import SidebarSignUp from "./SidebarSignUp.jsx";
import TPCPriorityGroupForm from "./TPCPriorityGroupForm.jsx";

const comm_options = {
  loop: true,
  nav: true,
  margin: 1,
  items: 1,
  dots: false,
  autoplay: true,
  autoplayHoverPause: true,
};

class CommBanner extends React.Component {
  constructor() {
    super();
    this.state = {
      show: null,
      showHide: false,
      innerModal: "",
      formvisible: true,
      tourvisible: true,
      utourtpc: true,
      utourtour: true,
      reserveform: true,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({ show: null });
  }
  updateInnerModal(str) {
    this.setState({ innerModal: str });
    this.setState({ utourtpc: false });
    this.setState({ utourtour: true });
    console.log("tpc", this.state.utourtpc);
    console.log("tour", this.state.utourtour);
  }
  updateInnerModal1(str) {
    this.setState({ innerModal: str });
    this.setState({ utourtpc: true });
    this.setState({ utourtour: false });
    console.log("tpc", this.state.utourtpc);
    console.log("tour", this.state.utourtour);
  }
  handleShow(id) {
    this.setState({ show: id });
  }
  handleModalShowHide() {
    this.setState({ showHide: !this.state.showHide });
    this.setState({ formvisible: false });
    this.setState({ tourvisible: true });
    this.setState({ reserveform: false });
    this.setState({ utourtour: true });
    console.log("reserveform", this.state.reserveform);
    console.log("tour", this.state.utourtour);
  }
  handleModalShowHide1() {
    this.setState({ showHide: !this.state.showHide });
    this.setState({ formvisible: true });
    this.setState({ reserveform: true });
    this.setState({ utourtour: false });
    console.log("reserveform", this.state.reserveform);
    console.log("tour", this.state.utourtour);
  }
  handleModalShowHide2() {
    this.setState({ tourvisible: false });
  }
  handleModalShowHide3() {
    this.setState({ utourtour: false });
    this.setState({ reserveform: true });
    console.log("reserveform", this.state.reserveform);
    console.log("tour", this.state.utourtour);
  }
  componentDidMount() {
    if (window.location.href.indexOf("#interest-form") > -1) {
      document.getElementById("interest_click_btn").click();
    }
    if (window.location.href.indexOf("#tour-form") > -1) {
      document.getElementById("tour_click_btn")?.click();
    }
  }
  handleSelect() {
    console.log("==== on changed ====");
    if ($(".owl-item").hasClass("active")) {
      var iframe = $(".owl-item.active .hero_iframe");
      if (iframe != "undefined") {
        var src = $(iframe).attr("src");
        $(iframe).attr("src", src);
      }
    }
  }
  split_string(str, count = 2, dir = "left", splitBy = " ") {
    var part1, part2;
    var split_array = str.split(splitBy);
    if (dir == "right") {
      part2 = split_array.splice(split_array.length - count).join(splitBy);
      part1 = split_array.join(splitBy);
    } else {
      part1 = split_array.splice(0, count).join(splitBy);
      part2 = split_array.join(splitBy);
    }
    return [part1, part2];
  }
  direction_data_layer_push(param) {
    this.setState({ value: param });
    if (param && param === "drivingDirections") {
      window.dataLayer.push({ event: "drivingDirections" });
    }
  }
  render() {
    var community_details = this.props.community_details;
    var posttype = this.props.posttype;
    var tpc_form_btn =
      community_details.tpc_priority_group_form &&
      community_details.tpc_priority_group_form.show_btn;
    var comm_title =
      community_details.title && this.split_string(community_details.title);
    var highlight_class = "green-color";
    var text_class = "gray-color";
    var url_split, id_url;
    var backgroundImage = community_details.banner_image;
    var tpc = false;
    var rth = false;
    var sat = false;
    var it = false;

    if (posttype === "qmi") {
      for (var i = 0; i < 2; i++) {
        if (community_details.active_form[i] == "tpc_priority") {
          tpc = true;
        } else if (community_details.active_form[i] == "reserve_this_home") {
          rth = true;
        } else if (community_details.active_form[i] == "schedule_a_tour") {
          sat = true;
        } else if (community_details.active_form[i] == "interest_list") {
          it = true;
        }
      }
    }
    if (backgroundImage !== "") {
      var background_image = {
        backgroundImage: "url(" + backgroundImage + ")",
      };
    }
    return (
      <div className="Banner-Main">
        <div className="banner-top-section">
          <div className="banner-first-section">
            <div className="banner-left-section">
              {comm_title ? (
                <h2 className={"comm-name " + (text_class || "")}>
                  <span className={highlight_class || ""}>{comm_title[0]}</span>{" "}
                  {comm_title[1]}
                </h2>
              ) : (
                ""
              )}
              {community_details.city || community_details.state_title ? (
                <h1 className="comm-price">
                  {community_details.title == "Avid" ||
                  community_details.title == "Vita" ||
                  community_details.title == "Mira" ||
                  community_details.title == "Elan" ||
                  community_details.title == "Altis" ||
                  community_details.title == "Altis at Terramor" ||
                  community_details.title == "Altis Beaumont" ||
                  community_details.title == "Altis at Skyline"
                    ? "New 55+ Homes in " +
                      community_details.city +
                      ", " +
                      community_details.state_title +
                      ". "
                    : "New homes in " +
                      community_details.city +
                      ", " +
                      community_details.state_title +
                      ". "}
                  {community_details.price_value &&
                  community_details.community_status !== "Sold Out" &&
                  posttype !== "neighborhood"
                    ? " " + community_details.price_value
                    : ""}
                  {community_details.price_value && posttype === "neighborhood"
                    ? " " + community_details.price_value
                    : ""}
                </h1>
              ) : (
                ""
              )}
              {posttype === "neighborhood"
                ? [
                    community_details.community_status ? (
                      <span class="card-status">
                        {community_details.community_status}
                      </span>
                    ) : (
                      ""
                    ),
                    community_details.neighborhood_marketing_headline ? (
                      <h2 class="comm-price marketing-headline">
                        {community_details.neighborhood_marketing_headline}
                      </h2>
                    ) : (
                      ""
                    ),
                  ]
                : [""]}
            </div>
            <ul className="comm-info">
              {community_details.square_feet ? (
                <li>
                  <span className="info-value">
                    {community_details.square_feet}
                  </span>
                  <span className="info-name">Sq.{"\u00A0"}Footage</span>
                </li>
              ) : (
                ""
              )}
              {community_details.bed ? (
                <li>
                  <span className="info-value">{community_details.bed}</span>
                  <span className="info-name">
                    {community_details.bed === "1" ? "Bedroom" : "Bedrooms"}
                  </span>
                </li>
              ) : (
                ""
              )}
              {community_details.bath ? (
                <li>
                  <span className="info-value">{community_details.bath}</span>
                  <span className="info-name">
                    {community_details.bath === "1" ? "Bathroom" : "Bathrooms"}
                  </span>
                </li>
              ) : (
                ""
              )}
              {community_details.garage ? (
                <li>
                  <span className="info-value">{community_details.garage}</span>
                  <span className="info-name">Garage</span>
                </li>
              ) : (
                ""
              )}
              {posttype === "qmi"
                ? [
                    community_details.plan_type ? (
                      <li>
                        <span className="info-value">
                          {community_details.plan_type}
                        </span>
                        <span className="info-name">Plan</span>
                      </li>
                    ) : (
                      ""
                    ),
                    community_details.move_in_date ? (
                      <li>
                        <span className="info-value">
                          {community_details.move_in_date}
                        </span>
                        <span className="info-name">
                          move{"\u00A0"}-{"\u00A0"}in
                        </span>
                      </li>
                    ) : (
                      ""
                    ),
                  ]
                : [""]}
            </ul>
          </div>
          <div className="banner-second-section">
            <div className="banner-bottom-section">
              <ul>
                {/* {community_details.community_status ? (
                                    <li className="comm-status">{community_details.community_status}</li>
                                ):('')} */}
                {community_details.hide_address !== true
                  ? [
                      <div className="banner-address">
                        {community_details.sales_office_address.sales_address ||
                        community_details.sales_office_address
                          .sales_address_heading
                          ? [
                              <p className="divide-equal sales-address">
                                {posttype === "qmi" ||
                                posttype === "neighborhood" ? (
                                  community_details.community_status !=
                                  "Temporarily Sold Out" ? (
                                    community_details.sales_office_address
                                      .sales_address_heading ? (
                                      <li
                                        className="time"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            community_details.sales_office_address.sales_address_heading.replace(
                                              /(?:\r\n|\r|\n)/g,
                                              "",
                                            ),
                                        }}
                                      ></li>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    <li className="time">
                                      Our New Home Gallery is located at
                                    </li>
                                  )
                                ) : community_details.sales_office_address
                                    .sales_address_heading ? (
                                  <li
                                    className="time"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        community_details.sales_office_address.sales_address_heading.replace(
                                          /(?:\r\n|\r|\n)/g,
                                          "",
                                        ),
                                    }}
                                  ></li>
                                ) : (
                                  ""
                                )}
                                {community_details.sales_office_address
                                  .sales_address ? (
                                  <li className="time">
                                    {
                                      community_details.sales_office_address
                                        .sales_address
                                    }
                                  </li>
                                ) : (
                                  ""
                                )}
                              </p>,
                            ]
                          : [""]}

                        {community_details.physical_address.comm_address ||
                        community_details.physical_address
                          .physical_address_heading
                          ? [
                              <p className="divide-equal">
                                {posttype === "qmi" ||
                                posttype === "neighborhood" ? (
                                  community_details.community_status !=
                                  "Temporarily Sold Out" ? (
                                    community_details.physical_address
                                      .physical_address_heading ? (
                                      <li
                                        className="time"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            community_details.physical_address.physical_address_heading.replace(
                                              /(?:\r\n|\r|\n)/g,
                                              "",
                                            ),
                                        }}
                                      ></li>
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    <li className="time">
                                      Our New Home Gallery is located at
                                    </li>
                                  )
                                ) : community_details.physical_address
                                    .physical_address_heading ? (
                                  <li
                                    className="time"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        community_details.physical_address.physical_address_heading.replace(
                                          /(?:\r\n|\r|\n)/g,
                                          "",
                                        ),
                                    }}
                                  ></li>
                                ) : (
                                  ""
                                )}
                                {community_details.physical_address
                                  .comm_address ? (
                                  <li className="time">
                                    {
                                      community_details.physical_address
                                        .comm_address
                                    }
                                  </li>
                                ) : (
                                  ""
                                )}
                              </p>,
                            ]
                          : [""]}
                      </div>,
                      community_details.sales_office_address.sales_address ||
                      community_details.sales_office_address
                        .sales_address_heading ? (
                        community_details.sales_office_address
                          .custom_direction_link ? (
                          <a
                            href={
                              community_details.sales_office_address
                                .custom_direction_link
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="driving-directions"
                            title="driving directions"
                            onClick={() =>
                              this.direction_data_layer_push(
                                "drivingDirections",
                              )
                            }
                          >
                            Get Directions
                          </a>
                        ) : (
                          ""
                        )
                      ) : community_details.physical_address
                          .map_directions_link ? (
                        <a
                          href={
                            community_details.physical_address
                              .map_directions_link
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          className="driving-directions"
                          title="driving directions"
                          onClick={() =>
                            this.direction_data_layer_push("drivingDirections")
                          }
                        >
                          Get Directions
                        </a>
                      ) : (
                        ""
                      ),
                    ]
                  : [""]}
                {/* {posttype === "qmi" || posttype === "neighborhood" ? (
                                    community_details.time_hour ?(
                                        community_details.community_status != 'Temporarily Sold Out' ? (
                                            <li className="city">{community_details.time_hour}</li>
                                        ):(
                                            <li className="city">open by appointment only</li>
                                        )
                                    ):('')
                                ):( */}
                {community_details.time_hour ? (
                  <li className="city">{community_details.time_hour}</li>
                ) : (
                  ""
                )}
                {/* )} */}
              </ul>
            </div>

            {posttype === "qmi"
              ? [
                  <div class="twoform">
                    {tpc && (
                      <React.Fragment>
                        <div className="schedule_tour_div">
                          <a
                            href="#tour_form"
                            data-fancybox="priority_group_form"
                            data-src="#tour_form"
                            className="qmi_button priorityform1 twobtn"
                            title="JOIN THE PRIORITY GROUP"
                            id="tour_click_btn_show"
                            onClick={() => {
                              window.dataLayer.push({
                                event: "preQualificationFormClick",
                              });
                            }}
                          >
                            Get pre-qualified
                          </a>
                        </div>
                        <TPCPriorityGroupForm
                          tpc_form_details={
                            community_details.tpc_priority_group_form
                          }
                          division_name={community_details.division_name}
                        />
                      </React.Fragment>
                    )}

                    {sat && (
                      <React.Fragment>
                        <div className="schedule_tour_div">
                          <a
                            href="javascript:void(0)"
                            className="qmi_button twobtn"
                            title="Schedule a Tour"
                            id="tour_click_btn"
                            onClick={() => {
                              this.handleModalShowHide();
                              window.dataLayer.push({
                                event: "scheduleTourFormClick",
                              });
                            }}
                          >
                            Schedule a Tour
                          </a>
                        </div>
                        <Modal
                          className="Utour-screen schedule-Modal tph-ui-modal"
                          show={this.state.showHide}
                        >
                          <Modal.Header
                            closeButton
                            onClick={() => this.handleModalShowHide1()}
                          ></Modal.Header>
                          <Modal.Body>
                            {this.state.utourtour ? (
                              <ScheduleTourForm
                                form_details={community_details.tour_details}
                                division_name={community_details.division_name}
                              />
                            ) : null}
                          </Modal.Body>
                        </Modal>
                      </React.Fragment>
                    )}
                    {rth && (
                      <React.Fragment>
                        <div className="schedule_tour_div">
                          <a
                            href="#tour_forms"
                            data-fancybox="tour_forms"
                            data-src="#tour_forms"
                            className="qmi_button twobtn"
                            id="tour_click_btns"
                            onClick={() => {
                              this.handleModalShowHide3();
                              window.dataLayer.push({
                                event: "homeReservationFormClick",
                              });
                            }}
                          >
                            {community_details.schedule_button_text}
                          </a>
                        </div>
                        {this.state.reserveform ? (
                          <QmiReserveForm
                            form_details={community_details.tour_details}
                            agent_name={community_details.agent_info}
                            division_name={community_details.division_name}
                            homesiteNumber={community_details.homesite_number}
                          />
                        ) : null}
                      </React.Fragment>
                    )}

                    {it && (
                      <React.Fragment>
                        <div className="schedule_tour_div">
                          <button
                            className="qmi_button twobtn"
                            title="Join The Interest List"
                            onClick={() => this.handleShow("Request_quote")}
                            id="interest_click_btn"
                          >
                            {community_details.interest_list_overrride
                              ? community_details.interest_list_overrride
                              : "Join The Interest List"}
                          </button>
                        </div>
                        <Modal
                          className="Estimate_modal Request_quote"
                          show={this.state.show === "Request_quote"}
                          onHide={this.handleClose}
                        >
                          <Modal.Header closeButton></Modal.Header>
                          <Modal.Body>
                            <SidebarSignUp
                              sidebar_data={community_details.sidebar_form}
                              communityOfInterest={
                                community_details.communityOfInterest
                              }
                              nbd_title={community_details.neigh_title}
                              division_name={community_details.division_name}
                            />
                          </Modal.Body>
                        </Modal>
                      </React.Fragment>
                    )}
                  </div>,
                ]
              : [
                  posttype === "neighborhood"
                    ? [
                        tpc_form_btn &&
                        community_details.tour_details.show_tour_btn
                          ? [
                              <React.Fragment>
                                <div class="twoforms">
                                  <div className="schedule_tour_div seconddiv">
                                    <button
                                      href="#"
                                      className="tour_filled_btn_first"
                                      title="Schedule a Tour"
                                      id="tour_click_btn"
                                      onClick={() => {
                                        this.handleModalShowHide();
                                        window.dataLayer.push({
                                          event: "scheduleTourFormClick",
                                        });
                                      }}
                                    >
                                      Schedule a Tour
                                    </button>
                                  </div>
                                  <Modal
                                    className="Utour-screen schedule-Modal tph-ui-modal"
                                    show={this.state.showHide}
                                  >
                                    <Modal.Header
                                      closeButton
                                      onClick={() =>
                                        this.handleModalShowHide1()
                                      }
                                    ></Modal.Header>
                                    <Modal.Body>
                                      {this.state.tourvisible && (
                                        <ScheduleTourForm
                                          form_details={
                                            community_details.tour_details
                                          }
                                          division_name={
                                            community_details.division_name
                                          }
                                        />
                                      )}
                                    </Modal.Body>
                                  </Modal>
                                  <div className="schedule_tour_div">
                                    <a
                                      href="#tour_form"
                                      data-fancybox="priority_group_form"
                                      data-src="#tour_form"
                                      className="tour_filled_btn prioritydiv"
                                      title="JOIN THE PRIORITY GROUP"
                                      id="tour_click_btn_show"
                                      onClick={() => {
                                        this.handleModalShowHide3();
                                        window.dataLayer.push({
                                          event: "preQualificationFormClick",
                                        });
                                      }}
                                    >
                                      Get pre-qualified
                                    </a>
                                  </div>
                                  {this.state.utourtpc ? (
                                    <TPCPriorityGroupForm
                                      tpc_form_details={
                                        community_details.tpc_priority_group_form
                                      }
                                      division_name={
                                        community_details.division_name
                                      }
                                    />
                                  ) : null}
                                </div>
                              </React.Fragment>,
                            ]
                          : [
                              tpc_form_btn
                                ? [
                                    <React.Fragment>
                                      <div className="schedule_tour_div">
                                        <a
                                          href="#tour_form"
                                          data-fancybox="priority_group_form"
                                          data-src="#tour_form"
                                          className="tour_filled_btn"
                                          title="JOIN THE PRIORITY GROUP"
                                          id="tour_click_btn_show"
                                          onClick={() => {
                                            window.dataLayer.push({
                                              event:
                                                "preQualificationFormClick",
                                            });
                                          }}
                                        >
                                          Get pre-qualified
                                        </a>
                                      </div>
                                      <TPCPriorityGroupForm
                                        tpc_form_details={
                                          community_details.tpc_priority_group_form
                                        }
                                        division_name={
                                          community_details.division_name
                                        }
                                      />
                                    </React.Fragment>,
                                  ]
                                : [
                                    community_details.tour_details.show_tour_btn
                                      ? [
                                          <div className="schedule_tour_div">
                                            <a
                                              href="#tour_form"
                                              data-fancybox="tour_form"
                                              data-src="#tour_form"
                                              className="tour_filled_btn"
                                              title="Schedule a Tour"
                                              id="tour_click_btn"
                                              onClick={() => {
                                                window.dataLayer.push({
                                                  event:
                                                    "scheduleTourFormClick",
                                                });
                                              }}
                                            >
                                              Schedule a Tour
                                            </a>
                                          </div>,
                                          <ScheduleTourForm
                                            form_details={
                                              community_details.tour_details
                                            }
                                            division_name={
                                              community_details.division_name
                                            }
                                          />,
                                        ]
                                      : [
                                          <div className="schedule_tour_div">
                                            {community_details.community_status !==
                                            "Sold Out" ? (
                                              <a
                                                href="javascript:void(0)"
                                                className="tour_filled_btn"
                                                title="Join The Interest List"
                                                id="interest_click_btn"
                                                onClick={() =>
                                                  this.handleShow(
                                                    "Request_quote",
                                                  )
                                                }
                                              >
                                                Join The Interest List
                                              </a>
                                            ) : (
                                              ""
                                            )}
                                          </div>,
                                          <Modal
                                            className="Estimate_modal Request_quote"
                                            show={
                                              this.state.show ===
                                              "Request_quote"
                                            }
                                            onHide={this.handleClose}
                                          >
                                            <Modal.Header
                                              closeButton
                                            ></Modal.Header>
                                            <Modal.Body>
                                              <SidebarSignUp
                                                sidebar_data={
                                                  community_details.sidebar_form
                                                }
                                                communityOfInterest={
                                                  community_details.communityOfInterest
                                                }
                                                nbd_title={
                                                  community_details.title
                                                }
                                                division_name={
                                                  community_details.division_name
                                                }
                                                neigh_status={
                                                  community_details.community_status
                                                }
                                              />
                                            </Modal.Body>
                                          </Modal>,
                                        ],
                                  ],
                            ],
                      ]
                    : [
                        tpc_form_btn
                          ? [
                              <React.Fragment>
                                <div className="schedule_tour_div">
                                  <a
                                    href="#tour_form"
                                    data-fancybox="priority_group_form"
                                    data-src="#tour_form"
                                    className="tour_filled_btn"
                                    title="JOIN THE PRIORITY GROUP"
                                    id="tour_click_btn_show"
                                    onClick={() => {
                                      window.dataLayer.push({
                                        event: "preQualificationFormClick",
                                      });
                                    }}
                                  >
                                    Get pre-qualified
                                  </a>
                                </div>
                                <TPCPriorityGroupForm
                                  tpc_form_details={
                                    community_details.tpc_priority_group_form
                                  }
                                  division_name={
                                    community_details.division_name
                                  }
                                />
                              </React.Fragment>,
                            ]
                          : [
                              community_details.tour_details.show_tour_btn
                                ? [
                                    <div className="schedule_tour_div">
                                      <a
                                        href="#tour_form"
                                        data-fancybox="tour_form"
                                        data-src="#tour_form"
                                        className="tour_filled_btn"
                                        title="Schedule a Tour"
                                        id="tour_click_btn"
                                        onClick={() => {
                                          window.dataLayer.push({
                                            event: "scheduleTourFormClick",
                                          });
                                        }}
                                      >
                                        Schedule a Tour
                                      </a>
                                    </div>,
                                    <ScheduleTourForm
                                      form_details={
                                        community_details.tour_details
                                      }
                                      division_name={
                                        community_details.division_name
                                      }
                                    />,
                                  ]
                                : [
                                    <div className="schedule_tour_div">
                                      {community_details.community_status !==
                                      "Sold Out" ? (
                                        <a
                                          href="javascript:void(0)"
                                          className="tour_filled_btn"
                                          title="Join The Interest List"
                                          id="interest_click_btn"
                                          onClick={() =>
                                            this.handleShow("Request_quote")
                                          }
                                        >
                                          Join The Interest List
                                        </a>
                                      ) : (
                                        ""
                                      )}
                                    </div>,

                                    <Modal
                                      className="Estimate_modal Request_quote"
                                      show={this.state.show === "Request_quote"}
                                      onHide={this.handleClose}
                                    >
                                      <Modal.Header closeButton></Modal.Header>
                                      <Modal.Body>
                                        <SidebarSignUp
                                          sidebar_data={
                                            community_details.sidebar_form
                                          }
                                          communityOfInterest={
                                            community_details.communityOfInterest
                                          }
                                          nbd_title={community_details.title}
                                          division_name={
                                            community_details.division_name
                                          }
                                          neigh_status={
                                            community_details.community_status
                                          }
                                        />
                                      </Modal.Body>
                                    </Modal>,
                                  ],
                            ],
                      ],
                ]}
          </div>
        </div>

        <div className="banner-img-section" style={background_image}>
          {community_details.master_community_snipe && (
            <div className="brand-logo">
              <img
                src={community_details.master_community_snipe.url}
                alt={community_details.master_community_snipe.title}
                title={community_details.master_community_snipe.title}
              />
            </div>
          )}
          {community_details.show_slider_data.length > 0 ? (
            <div className="banner-slider">
              <OwlCarousel
                {...comm_options}
                className="owl-theme"
                onChanged={() => this.handleSelect()}
              >
                {community_details.show_slider_data.map((item) =>
                  item.slider_image ? (
                    <div>
                      <img
                        src={item.slider_image}
                        alt="Banner"
                        title="banner"
                      />
                      <div className="item-bg-overlay"></div>
                      <div className="item-caption">
                        {item.slider_heading ? (
                          <h2
                            dangerouslySetInnerHTML={{
                              __html: item.slider_heading.replace(
                                /(?:\r\n|\r|\n)/g,
                                "",
                              ),
                            }}
                          ></h2>
                        ) : (
                          ""
                        )}
                        {item.slider_subheading ? (
                          <p>{item.slider_subheading}</p>
                        ) : (
                          ""
                        )}
                        {item.slider_link ? (
                          <a
                            href={item.slider_link}
                            className="item-btn"
                            title="learn more"
                          >
                            Learn More
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : item.slider_video ? (
                    ((url_split = item.slider_video.split("/")),
                    (id_url = url_split[url_split.length - 1]),
                    (
                      <div>
                        <iframe
                          className="hero_iframe"
                          src={item.slider_video + "?playlist=" + id_url}
                          title="banner iframe"
                        ></iframe>
                      </div>
                    ))
                  ) : (
                    ""
                  ),
                )}
              </OwlCarousel>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default CommBanner;
