import React from "react";
import axios from "axios";
import CommBanner from "../../Components/CommBanner";
import GlobalField from "../../Components/GlobalField";
import FaqSection from "../../Components/FaqSection";
import PlanCard from "../../Components/PlanCard";
import QmiCard from "../../Components/QmiCard";
import SubNavbar from "../../Components/SubNavbar";
import { apiConfig } from "../../Components/Helper.jsx";
import PromotionBlock from "../../Components/PromotionBlock";
import NhsDesktop from "../../Components/NhsDesktop";
import UtilityNav from "../../Components/UtilityNav";
import TPHLoader from "../../Components/TPHLoader.jsx";
import { Redirect } from "react-router-dom";
import DocumentMeta from "react-document-meta";
import LifestyleListing from "../../Components/LifestyleListing";
import $ from "jquery";
import NoMatch from "./NoMatch";

class Neighborhood extends React.Component {
  state = {
    neigh_component: [],
    success: false,
  };
  constructor() {
    super();
  }
  /*Local Business*/
  local_business_structured_data(neighComponent) {
    if (neighComponent) {
      if (neighComponent.agent_info.agents_contact_phone) {
        var telephone = neighComponent.agent_info.agents_contact_phone;
      }
      if (neighComponent.min_price) {
        var priceRange = neighComponent.min_price;
      }
      if (neighComponent.physical_address.physical_address_heading) {
        var addressLocality =
          neighComponent.physical_address.physical_address_heading;
      }
      if (neighComponent.streetAddress) {
        var streetAddress = neighComponent.streetAddress;
      }
      if (neighComponent.postalCode) {
        var postalCode = neighComponent.postalCode;
      }
      if (neighComponent.latitude) {
        var latitude = neighComponent.latitude;
      }
      if (neighComponent.longitude) {
        var longitude = neighComponent.longitude;
      }
      if (neighComponent.state_abb) {
        var state_abb = neighComponent.state_abb.toUpperCase();
      }
      if (neighComponent.site_logo) {
        var site_logo = neighComponent.site_logo;
      }
    }
    const script = document.createElement("script");
    // Structure Data script start
    script.type = "application/ld+json";
    script.innerHTML = `{
      "@context": "https://schema.org",
      "@type": "LocalBusiness",
      "name": "Altis 55+",
      "image": "${site_logo}",
      "@id": "",
      "url": "${window.location.href}",
      "telephone": "${telephone}",
      "priceRange": "${priceRange}",
      "address": {
      "@type": "PostalAddress",
      "streetAddress": "${streetAddress}",
      "addressLocality": "${addressLocality}",
      "addressRegion": "${state_abb}",
      "postalCode": "${postalCode}",
      "addressCountry": "US"
      },
      "geo": {
      "@type": "GeoCoordinates",
      "latitude": "${latitude}",
      "longitude": "${longitude}"
      }
    }`;
    //  Structure Data script end
    document.head.appendChild(script);
  }
  fetch_data() {
    axios
      .get(apiConfig().API_URL + "/neighborhood_info/" + this.props.slug)
      .then((response) => {
        this.setState({
          neigh_component: response.data.data,
          success: response.data.success,
          message: response.data.message,
        });
      });
  }
  componentDidMount() {
    this.fetch_data();
    window.CallTrk.swap();
    window.scrollTo(0, 0);
    window.JQUERY(".top-scroll").addClass("dynamic");
  }
  componentDidUpdate() {
    if (this.state.success == true) {
      window.dataLayer.push({
        event: "communityId",
        divisionName: this.state.neigh_component.neighborhood_details.title
          ? this.state.neigh_component.neighborhood_details.title
          : "",
        e1CommunityId: this.state.neigh_component.neighborhood_details
          .e1_community_id
          ? this.state.neigh_component.neighborhood_details.e1_community_id
          : "",
        salesforceId: this.state.neigh_component.neighborhood_details
          .communityOfInterest
          ? this.state.neigh_component.neighborhood_details.communityOfInterest
          : "",
      });
    }
    window.CallTrk.swap();
    window.JQUERY(".header_section").show();
    window.JQUERY(".main-outer-div").removeClass("residence-top");
    window.JQUERY(".footer_section").show();
    window.JQUERY(".top-scroll").show();
    window.JQUERY(".top-scroll").addClass("dynamic");
  }
  componentWillReceiveProps() {
    this.fetch_data();
    window.JQUERY(".top-scroll").addClass("dynamic");
  }
  render() {
    var window_width = window.screen.width;
    var success = this.state.success;
    var message = this.state.message;
    var neigh_component = this.state.neigh_component;
    var state_name = this.props.state.toLowerCase();
    var submarket_name = this.props.submarket.toLowerCase();
    var neighComponent = this.state.neigh_component.neighborhood_details;
    const meta = {
      title: neigh_component.yoast_title,
      description: neigh_component.yoast_desc,
      canonical: window.location.href,
    };
    if (neighComponent) {
      this.local_business_structured_data(neighComponent);
    }

    return (
      <>
        <DocumentMeta {...meta} />
        <div className="neighborhood-main">
          {success === true
            ? [
                state_name ===
                  neigh_component.neighborhood_details.state_abb.toLowerCase() &&
                submarket_name ===
                  neigh_component.neighborhood_details.submarket.toLowerCase() &&
                neigh_component.neighborhood_details.community_status !==
                  "Archive"
                  ? [
                      <SubNavbar
                        navbar_detail={neigh_component}
                        community_details={neigh_component.neighborhood_details}
                        posttype="neighborhood"
                      />,
                      neigh_component.neighborhood_details ? (
                        <CommBanner
                          community_details={
                            neigh_component.neighborhood_details
                          }
                          posttype="neighborhood"
                        />
                      ) : (
                        ""
                      ),
                      neigh_component.residence &&
                      neigh_component.neighborhood_details.show_plan_tab ===
                        true ? (
                        <PlanCard
                          plan_detail={neigh_component.residence}
                          section_id="home-plans"
                        />
                      ) : (
                        ""
                      ),
                      neigh_component.qmi ? (
                        <QmiCard
                          Qmi_detail={neigh_component.qmi}
                          id="available-homes"
                        />
                      ) : (
                        ""
                      ),
                      neigh_component.neighborhood_details.promotion_info ? (
                        <PromotionBlock
                          promo_data={
                            neigh_component.neighborhood_details.promotion_info
                          }
                        />
                      ) : (
                        ""
                      ),
                      <GlobalField
                        slug={this.props.slug}
                        global_data={
                          neigh_component.neighborhood_details.component
                        }
                      />,

                      neigh_component.neighborhood_details.lifestyle_data ? (
                        <LifestyleListing
                          lifestyle_data={
                            neigh_component.neighborhood_details.lifestyle_data
                          }
                        />
                      ) : (
                        ""
                      ),
                      <FaqSection
                        community_details={neigh_component.neighborhood_details}
                      />,
                      window_width < 767 ? (
                        <UtilityNav
                          sfdc_chat={
                            neigh_component.neighborhood_details
                              .sfdc_chat_details
                          }
                          agent_info={
                            neigh_component.neighborhood_details.agent_info
                          }
                          community_component={
                            neigh_component.neighborhood_details
                          }
                        />
                      ) : (
                        <NhsDesktop
                          sfdc_chat={
                            neigh_component.neighborhood_details
                              .sfdc_chat_details
                          }
                          agent_info={
                            neigh_component.neighborhood_details.agent_info
                          }
                          community_component={
                            neigh_component.neighborhood_details
                          }
                        />
                      ),
                    ]
                  : [""],
              ]
            : [
                message != "Not Completed" ? (
                  <TPHLoader />
                ) : (
                  <Redirect to={<NoMatch />} />
                ),
              ]}
        </div>
      </>
    );
  }
}

export default Neighborhood;
